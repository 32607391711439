import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Button, Icon, Modal, Segment } from 'semantic-ui-react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import headerImg from "../images/approve_annual_accounts_browserized.png"
import managementImg from "../images/nlive_meeting.gif"
import "./index.css"
import styled from "styled-components"

const Card = styled.div`
  padding: 10px !important;
  min-height: 300px !important;

  h3 {
    margin: 20px 0;
    text-align: center;
  }
`

const Divider = styled.div`
  margin: 50px 0 !important;
`

const MainTitle = styled.h1`
    font-size: 48px;
    font-weight: bold;
`

const MainSubtitle = styled(MainTitle)`
    color: #2185D0;
`

const RaisedHeader = styled.h2`
    color: #2185D0;
`

const RaisedHeader3 = styled.h3`
    color: #2185D0;
`

const HomeSegment = styled.div`
    margin-top: -30px !important;
`

function nLivePage({title, subtitle, description, showCallToAction}) {
  return (
    <Layout>
      <SEO
        title="nLive - real-time voting"
        keywords={[`nvotes`, `secure`, `online`, `voting`, `agm`, `assembly`, `realtime`, `election`, `real-time`]}
      />

        <div className="ui vertically divided centered middle aligned stackable grid container">
          <div className="two column row home-main-segment">
            <HomeSegment className="column">
              <Segment vertical>
                  <MainSubtitle>{subtitle}</MainSubtitle>
                  <p>{description}</p>
                  {showCallToAction &&
                    <Link to="/contact">
                      <Button animated="fade" color="blue" size="huge">
                        <Button.Content visible>Help me organize an election</Button.Content>
                        <Button.Content hidden>
                          Help me organize an election &nbsp;
                          <Icon name='arrow right' />
                        </Button.Content>
                      </Button>
                    </Link>
                  }
                </Segment>
            </HomeSegment>
            <div className="column">
                <img src={headerImg} alt="online voting" />
            </div>
          </div>

            <div className="ui vertically divided centered middle aligned stackable grid container">
              <div className="four column row">
                <div className="column">
                    <div className="ui blue statistic">
                      <div className="value">
                        +2M
                      </div>
                      <div className="label">
                        votes cast
                      </div>
                    </div>
                </div>
                <div className="column">
                <div className="ui blue statistic">
                  <div className="value">
                    +150K
                  </div>
                  <div className="label">
                    votes on 1 election
                  </div>
                </div>
              </div>
                <div className="column">
                  <div className="ui blue statistic">
                    <div className="value">
                      +150
                    </div>
                    <div className="label">
                      customers
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="ui blue statistic">
                    <div className="value">
                      +700
                    </div>
                    <div className="label">
                      simultaneous elections
                    </div>
                  </div>
                </div>
              </div>
            </div>


          <div className="two column row home-data-row">
            <div className="column">
              <div className="ui raised segment" style={{padding: 0}}>
                <iframe
                  title="Voter experience video"
                  src="https://www.youtube-nocookie.com/embed/HyT3LhYT4Zg?rel=0&autoplay=1"
                  width="100%"
                  height="280px"
                  style={{margin: 0, border: 0}}
                  allowFullScreen="allowFullScreen">
                </iframe>
              </div>
            </div>
            <div className="column">
              <RaisedHeader><span>A smooth voter experience</span></RaisedHeader>
              <p>Voters authenticate from any device. They can cast a vote in the currently active question with a simple click. It doesn't get any easier.</p>
                <Modal closeIcon trigger={
                  <Button animated="fade" basic color="blue" size="big">
                    <Button.Content visible>View video</Button.Content>
                    <Button.Content hidden>
                      View video &nbsp;
                      <Icon name='arrow right' />
                    </Button.Content>
                  </Button>
                }>
                <Modal.Content style={{padding: 0}}>
                  <Modal.Description>
                    <iframe
                      title="voter experience video (same as before)"
                      src="https://www.youtube-nocookie.com/embed/HyT3LhYT4Zg?&autoplay=1&rel=0&enablejsapi=true"
                      width="100%"
                      height="600px"
                      frameborder="0"
                      style={{margin: 0, border: 0}}
                      allowFullScreen="allowFullScreen">
                    </iframe>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            </div>
          </div>
          <div className="two column row home-data-row">
            <div className="column">
              <RaisedHeader><span>Election management for everyone</span></RaisedHeader>
              <p>The interface doesn't get in the way. You can obtain election results in real-time with a live election account. You can add a new question at any time  - even in the middle of an election. nLives also allows you to configure different weight for each voter. And with a click you can see a detailed audit log.</p>
            </div>
            <div className="column">
              <div className="ui raised segment">
                <Modal closeIcon trigger={
                  <img src={managementImg} alt="Online live voting" className="ui image" />
                }>
                <Modal.Content>
                  <Modal.Description>
                    <img src={managementImg} alt="Online Management Software" className="ui image massive" />
                  </Modal.Description>
                </Modal.Content>
              </Modal>
              </div>
            </div>
          </div>
        </div>
        <Divider className="ui divider"></Divider>

        <div className="ui centered middle aligned stackable grid container home-data-row">
          <RaisedHeader>Our customers</RaisedHeader>

          <p>
            Our software has been used all over the world in elections with up to
            2,7 million eligible voters and +150.000 votes cast.&nbsp;
            <Link to="/customers/">See some customer
            references</Link>.
          </p>
          <div className="ui three column row">
            <div className="column">
              <Card className="ui fluid card">
                <div className="content">
                  <div className="header">
                    <RaisedHeader3>Annual General Meetings</RaisedHeader3>
                  </div>
                  <div className="description">
                    <p>Whether you are a company, a federation or an association, nLive can be an effective tool to get the job done safe and secure during a vote in an Annual General Meeting.</p>
                  </div>
                </div>
              </Card>
            </div>
            <div className="column">
              <Card className="ui fluid card">
                <div className="content">
                  <div className="header">
                    <RaisedHeader3>Partner with us</RaisedHeader3>
                  </div>
                  <div className="description">
                    <p>nLive can be your trustworthy partner that can provide the platform for voting while you deliver other value-added services like organizing the event, configuring the election or voter-support.</p>
                  </div>
                </div>
              </Card>
            </div>
            <div className="column">
              <Card className="ui fluid card">
                <div className="content">
                  <div className="header">
                    <RaisedHeader3>Other organizations and needs</RaisedHeader3>
                  </div>
                  <div className="description">
                    <p>Unions, professional bodies, cooperatives, schools, etc... also use nLive to solve their live voting needs. It can be used for all kind of live elections online or on-site. We also provide custom developments and deployments.</p>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className="ui hidden divider"></div>
        <div className="ui hidden divider"></div>
    </Layout>
  )
}

nLivePage.defaultProps = {
  title: '',
  subtitle: 'Real-time online voting during meetings',
  description: 'Just what you needed to vote.',
  showCallToAction: true
}

nLivePage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  showCallToAction: PropTypes.bool
}

export default nLivePage
